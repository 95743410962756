@import "../../styles/variables";

.card {
  border-radius: 8px;
  padding: 24px;
  text-align: left;
}
.lightbg {
  background-color: $white;
  border: 1px solid #dedede;
}
.darkbg {
  background-color: #f8f8f9;
  border: 1px solid #a8a8a8;
}

.noBorder {
  border: none;
}

.outline {
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
}
