@import "../../styles/variables";

.container {
  display: grid;
  gap: 5px;
  width: fit-content;
  font-family: CharlieDisplay;
  margin-bottom: 0;
  &_left {
    align-items: center;
    gap: 10px;
    grid-auto-flow: column;
  }
}

.avatarImageWrapper {
  border-radius: 2000px;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  &:hover {
    .tag {
      background-color: rgba(255, 255, 255, 0.281);
      transform: translateY(0%);
      &_loading {
        background-color: $grey;
      }
    }
    .avatarImage {
      filter: blur(1px);
      transform: scale(1.1);
    }
  }
}

.avatarImage {
  transition: all 0.5s;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.tag {
  border-radius: 2000px;
  transition: all 0.5s;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(120%);
  &_loading {
    background-color: $grey;
    transform: translateY(0%);
  }
  &__text {
    text-transform: capitalize;
    color: white;
    margin: 0;
  }
}

.label {
  color: $black;
}
