@import "../../styles/variables";

.OptionsContainer {
  position: absolute;
  right: 25px;
  top: 27px;
  z-index: 1;
  min-width: 150px;
  @media (max-width: 480px) {
    min-width: 100px;
    right: 2px;
    top: 30px;
  }
}
.OptionCard {
  width: 100%;
  box-shadow: 30px 17px 100px 0 rgba(192, 237, 226, 0.41);
  padding: 10px 0 7px 0 !important;
  min-height: unset !important;
  align-items: start !important;
  border: solid 0.3px rgba(151, 151, 151, 0.3) !important;
  display: none;
}
.CogContainer {
  position: relative;
  cursor: pointer;
  margin-left: auto;
  max-width: 200px;
}

.CogConStatic {
  position: relative;
  cursor: pointer;

  &:hover {
    .OptionCard {
      display: flex;
    }
  }
}

.cardItem {
  font-size: 14px;
  cursor: pointer;
  padding: 5px 18px 5px 15px;
  width: 100%;
  &:hover {
    background-color: $deepGrey;
  }
}
