@import "../../styles/variables";

.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.modalHeader {
  background: #eaeaea;
  &__title {
    font-weight: normal;
    font-size: 25px;
    line-height: 38px;
    color: $black2;
    letter-spacing: 0.01em;
  }
  @media (max-width: 480px) {
    flex-direction: column;
  }
}

.modal_content {
  background-color: #fff;
  width: 558px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 10px;
  box-shadow: 30px 17px 100px 0 #220060;
  border: solid 0.3px #390099;
  margin: auto;
}
.modal_backdrop {
  background-color: rgba(34, 0, 96, 0.91);
}

.modal_backdrop .show {
  background-color: rgba(34, 0, 96, 0.91);
  opacity: 0;
}
.fullScreenModal {
  width: 100%;
  height: 100%;
  max-width: unset;
  max-height: unset;
  margin: 0;
  border-radius: 0;
  &__content {
    border-radius: 0;
  }
}
