@font-face {
  font-family: "CharlieDisplay";
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  src: url("../assets/fonts/CharlieDisplay-Regular.otf");
}

@font-face {
  font-family: "CharlieDisplay";
  font-style: italic;
  font-weight: normal;
  src: url("../assets/fonts/CharlieDisplay-RegularItalic.otf");
}

@font-face {
  font-family: "CharlieDisplay";
  font-style: normal;
  font-weight: 300;
  src: url("../assets/fonts/CharlieDisplay-Thin.otf");
}

@font-face {
  font-family: "CharlieDisplay";
  font-style: italic;
  font-weight: 300;
  src: url("../assets/fonts/CharlieDisplay-ThinItalic.otf");
}

@font-face {
  font-family: "CharlieDisplay";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/CharlieDisplay-Semibold.otf");
}
@font-face {
  font-family: "CharlieDisplay";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/CharlieDisplay-Semibold.otf");
}

@font-face {
  font-family: "CharlieDisplay";
  font-style: italic;
  font-weight: 500;
  src: url("../assets/fonts/CharlieDisplay-SemiboldItalic.otf");
}

@font-face {
  font-family: "CharlieDisplay";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/CharlieDisplay-Bold.otf");
}

@font-face {
  font-family: "CharlieDisplay";
  font-style: italic;
  font-weight: 700;
  src: url("../assets/fonts/CharlieDisplay-BoldItalic.otf");
}

// @font-face {
//   font-family: "CharlieDisplay";
//   font-style: normal;
//   font-weight: bold;
//   src: url("../assets/fonts/CharlieDisplay-Black.otf") format("otf");
// }

// @font-face {
//   font-family: "CharlieDisplay";
//   font-style: italic;
//   font-weight: bold;
//   src: url("../assets/fonts/CharlieDisplay-BlackItalic.otf") format("otf");
// }

@mixin charlieDisplay {
  font-family: CharlieDisplay;
}
