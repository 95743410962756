@import "../../styles/variables";

.container {
  
}

.labelEl {
  display: flex;
  align-items: center;
  &__text{
    margin-bottom: 0;
    margin-left: 10px;
    color: $black2;
    font-size: 16px;
  }
}

.inputEl {
  display: none;
}
