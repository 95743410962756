@import "styles/variables";
@import "styles/mixins";

.activeContainer {
  @include flex-row-center;
  background: #e0f5e6;
  border: 1px solid #eaeaea;
  border-radius: 48px;
  color: $green;
  padding: 4px 11px;
  width: 112px;
  cursor: pointer;
}
.inactiveContainer {
  @include flex-row-center;
  background: rgba(247, 85, 157, 0.1);
  border: 1px solid $red;
  border-radius: 48px;
  color: $red;
  padding: 4px 11px;
  width: 112px;
  cursor: pointer;
}
