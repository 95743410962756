@import "styles/variables";
.incidentVisual {
  height: 96px;
  width: 100%;
  border: 1px solid #c4c4c4;
  position: relative;
  margin-bottom: 32px;
}
.incidentClassCon {
  background: $deepGrey !important;
}
