@import "styles/variables";

.settlementCard {
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.01em;
  padding: 5.5px 20px !important;
  text-align: center;
}
.label {
  margin-bottom: 12px;
  font-weight: 600;
}
