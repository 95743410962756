@import "styles/variables";
@import "styles/mixins";

.approvedContainer {
  @include flex-row-center;
  background: #e0f5e6;
  border: 1px solid #eaeaea;
  border-radius: 48px;
  color: $green;
  padding: 4px 11px;
  width: 112px;
  cursor: pointer;
}

.reviewContainer {
  @include flex-row-center;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  width: 104px;
  padding: 4px 11px;
  cursor: pointer;
}

.addressTd {
  max-width: 270px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  @media (max-width: 480px) {
    max-width: 200px;
  }
}
