.behaviourLabel {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 12px;
  max-width: 185px;
  justify-content: space-between;
  margin-top: 20px;

  &::after {
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    top: 50px;
    margin-left: -2px;
    border-right: 1px dashed #307ec4;
  }

  &__text {
    color: #925acf;
    letter-spacing: 0.01em;
    font-weight: 500;
  }
  &__oval {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #aee097;
    margin-right: 13px;
  }
}
.behaviourLabel__no_psuedo {
  &::after {
    display: none;
  }
}

.headerTr {
  border-bottom: 0;
  height: 60px;
  &__th {
    font-weight: 500;
  }
}

.valuesTableContainer {
  &::before {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    top: -7px;
    left: -6px;
    border-radius: 4px;
    background-color: #fff100;
  }
}

.valuesTable {
  width: 100%;
  &::before {
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    left: -2px;
    border-right: 1px dashed #ff0000;
  }
  &::after {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    bottom: 0;
    left: -6px;
    border-radius: 4px;
    background-color: #fff100;
  }
}
.dateLabel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  padding: 8px 12px;
  border-radius: 22px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}
