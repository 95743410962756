// Override Default Variables
// https://getbootstrap.com/docs/5.0/customize/sass/#variable-defaults

$primary: #307ec4;
$secondary: #9da0a5;

$white: #fff;
$grey: #9da0a5;
$deepGrey: #ebe7e7;
$black: #1e2323;
$redLight: #ff57a1;
$lightGrey: #f8f8f9;
$green: #56cb77;
$black3: #e7eaea;
$black2: #3f3b3b;
$red: #ff2c2c;
$skyBlue: #e7eefb;
$fadedGreen: #e0f5e6;
$fadedRed: rgba(247, 85, 157, 0.1);
$line-height-base: 1.18;
$font-family-base: CharlieDisplay;
$text-muted: #9da0a5;
$font-weight-bold: 500 !default;
$modal-inner-padding: 2.5rem;
$modal-header-padding-x: 2.5rem;
$modal-content-border-radius: 1rem;
$toast-color: #fff;
$toast-font-size: 0.9rem;
