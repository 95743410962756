.PaginationContainer {
  width: 100%;
  // height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  background: white;
}
.pagination {
  position: relative;
  display: flex;
  list-style: none;
  margin-left: 0px;
  margin-top: 33px;
  align-items: center;
  padding: 0px 0px;
  margin-bottom: 0px;
  color: #307ec4;
  transform: translate(-50%, -50%);
}

.previousLabel {
  border: solid 1px #307ec4;
  background-color: #ffffff;
  border-radius: 10px;
  text-decoration: none;
  display: flex;
  width: 75px;
  height: 37px;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: none;
  outline: none;
}
.previousLinkLabel {
  width: 75px;
  height: 37px;
  color: #307ec4;
  display: flex;
  margin: auto;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: none;
  outline: none;
  text-decoration: none;
  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
    color: #307ec4;
    background-color: rgba(116, 207, 186, 0.08);
  }
}
.nextLabel {
  border: solid 1px #307ec4;
  background-color: #ffffff;
  border-radius: 10px;
  text-decoration: none;
}
.nextLinkLabel {
  width: 75px;
  height: 37px;
  color: #307ec4;
  display: flex;
  margin: auto;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: none;
  outline: none;
  text-decoration: none;
  &:active,
  &:focus,
  &:hover {
    border: none;
    text-decoration: none;
    color: #307ec4;
    background-color: rgba(116, 207, 186, 0.08);
  }
}

.activeLabel {
  width: 37px;
  height: 37px;
  display: flex;
  background-color: #307ec4;
  border-radius: 10px;
  margin: auto;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  box-shadow: 0 0 5px 0 #307ec4;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  border-color: #307ec4;
  outline: 0px;
  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
    outline: 0px;
    color: #ffffff;
  }
  a:active,
  a:hover {
    color: #ffffff;
  }
}

.activeLinkLabel {
  width: 37px;
  height: 37px;
  display: flex;
  background-color: #307ec4;
  border-radius: 10px;
  margin: auto;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff !important;
  outline: 0px;
  border-color: #307ec4;
  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
    outline: 0px;
    color: #ffffff !important;
  }
  a:active,
  a:hover {
    color: #ffffff !important;
  }
}

.pageLabel {
  width: 37px;
  height: 37px;
  color: #307ec4;
  border-radius: 10px;
  box-shadow: 0 0 5px 0 #307ec4;
  background-color: #ffffff;
  margin: 0px 8px;
  display: flex;
  font-size: 16px;
  align-items: center;
  font-weight: bold;
  justify-content: center;
  text-align: center;
  outline: 0px;
  color: #307ec4;
  font-weight: bold;
  &:active,
  &:focus {
    text-decoration: none;
    outline: 0px;
  }
  &:hover {
    text-decoration: none;
    color: #307ec4;
  }
  a {
    color: #307ec4;
  }
}

.pageLinkLabel {
  width: 37px;
  height: 37px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  border: solid 1px #307ec4;
  outline: 0px;
  text-decoration: none;
  color: #ffffff;
  &:active,
  &:focus {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
  }

  a:active,
  a:focus {
    color: #ffffff;
  }
}
.breakLabel {
  color: #220060;
  width: 37px;
  height: 37px;
  border-radius: 10px;
  margin: 0px 3px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  justify-content: center;
  text-align: center;
  box-shadow: 0 0 5px 0 #307ec4;
  background-color: #ffffff;
  border: solid 1px #307ec4;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: #307ec4;
  }
}

.breakLinkLabel {
  outline: 0px;
  color: #220060;
  width: 37px;
  height: 37px;
  border-radius: 10px;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: #220060;
  }
}
.disabled {
  cursor: not-allowed;
}
