@import "../../styles/variables";

.tabs {
  display: grid;
  grid-auto-flow: column;
  margin: auto;
  width: fit-content;
  gap: 10px;
  justify-content: center;
}

.container {
  border-top: 1px solid $black3;
}

.tab {
  &__container {
    cursor: pointer;
    padding: 4px;
    border: 1px solid $black3;
    border-top: none;
    min-width: 150px;
    justify-content: center;
    display: flex;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    &_active {
      color: white;
      background-color: $primary;
    }
  }
  &__text {
    width: max-content;
    text-align: center;
    font-size: 16px;
    font-weight: normal;
    margin: 5px 2px ;
  }
}
