// .paginationWrapper {
//   display: flex;
//   width: 100%;
//   align-items: center;
//   position: fixed;
//   bottom: 0;
//   background: white;
//   margin-bottom: 0;
// }
.paginationContainer {
  display: flex;
  list-style-type: none;
  width: 100%;
  align-items: center;
  // position: fixed;
  bottom: 0;
  background: white;
  margin-bottom: 0;
  padding: 20px 0;
  padding-left: 45px;
  border-top: 1px solid #eee;
}

.paginationItem {
  width: 37px;
  height: 37px;
  color: #307ec4;
  border-radius: 10px;
  box-shadow: 0 0 5px 0 #307ec4;
  background-color: #ffffff;
  margin: 0px 8px;
  display: flex;
  font-size: 16px;
  align-items: center;
  font-weight: bold;
  justify-content: center;
  text-align: center;
  outline: 0px;

  &__dots:hover {
    background-color: transparent;
    cursor: default;
  }
  &:hover {
    color: #fff;
    background-color: #307ec4;
    cursor: pointer;
  }

  &__selected {
    color: #fff;
    background-color: #307ec4;
  }
}

.arrow {
  font-size: 18px;
}

.disabled {
  pointer-events: none;

  .arrow::before {
    border-right: 0.12em solid rgba(0, 0, 0, 0.43);
    border-top: 0.12em solid rgba(0, 0, 0, 0.43);
  }

  &:hover {
    background-color: transparent;
    cursor: default;
  }
}
