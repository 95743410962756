@mixin spin {
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@mixin flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@mixin flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
