.loginPage {
  margin-top: 77px;
}
.intro {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 69px;
}
.formTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.01em;
  margin-bottom: 32px;
}
.loginBtn {
  margin-top: 32px;
}
