@import "../../styles/variables";

.container {
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  &:hover {
    .label {
      &__container {
        background-color: rgba(255, 255, 255, 0.281);
        transform: translateY(0%);
        &_loading {
          background-color: $deepGrey;
        }
      }
    }
    .image {
      filter: blur(1px);
      transform: scale(1.1);
    }
  }
}

.wrapper {
}

.image {
  transition: all 0.5s;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.label {
  &__container {
    border-radius: 5px;
    transition: all 0.5s;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.281);
    transform: translateY(120%);
    &_loading {
      background-color : $deepGrey;
      transform: translateY(0%);
    }
  }
  &__text {
    text-transform: capitalize;
    color: white;
    margin: 0;
  }
}
