@import "styles/variables";
@import "styles/mixins";

.sideContainer {
  @include flex-row-center;
  background: rgb(0 128 0 / 70%);
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 12px;
  color: white;
  overflow-x: clip;
  width: 65px;
}
