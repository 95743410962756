@import "styles/variables";
@import "styles/mixins";

.riskDetailTr {
  height: 100px;
  &:hover {
    cursor: inherit;
    background-color: inherit;
  }
}
