@import "../../styles/variables";

* {
  font-family: CharlieDisplay;
}

p {
  margin: 0;
  padding: 0;
}

.container {
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  // width: fit-content;
}

.statContainerClass {
  min-height: 103px;
  margin: 24px 24px 0 24px;
  padding-bottom: 24px;
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
  &:not(:last-child) {
    border-bottom: 1px solid #e2e2e2;
  }
}

p.statTitleClass {
  color: $grey;
  font-size: 16px;
}

p.statValueClass {
  color: $primary;
  font-size: 24px;
  margin-top: 8px;
  font-weight: 600;
}

p.statPercentageClass {
  margin-top: 8px;
  font-size: 14px;
  color: $green;
  &_negative {
    color: $redLight;
  }
}

.statBottomClass {
  margin-top: 8px;
}
