@import "styles/variables";
@import "styles/mixins";

.IconContainer {
  height: 20px;
  width: 20px;
  border-radius: 20px;
  background-color: #eee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.controlLabel {
  color: $secondary;
  margin-bottom: 12px;
  display: block;
}
.successContainer {
  @include flex-col-center;
  background-color: $fadedGreen;
  width: 40px;
  height: 32px;
  border-radius: 8px;
}
.dangerContainer {
  @include flex-col-center;
  background-color: $fadedRed;
  width: 40px;
  height: 32px;
  border-radius: 8px;
}
