@import "../../styles/variables";
@import "../../styles/mixins";

.customSugesstions {
  width: 100%;
  max-height: 320px;
  overflow-y: scroll;
  background-color: $white;
  padding: 16px 0;
  // border-top: solid 1px #e2e2e2;
  // border-bottom-left-radius: 10px;
  // border-bottom-right-radius: 10px;
  border-radius: 8px;
  position: absolute;
  left: -1px;
  right: 0px;
  z-index: 1;
  border: 1px solid $secondary;
  top: 47px;

  div {
    height: 44px;
    font-size: 16px;
    line-height: 2.25;
    color: $black;
    display: flex;
    white-space: nowrap;
    cursor: pointer;
    padding-left: 23px;
    padding-right: 23px;
    &:hover {
      background-color: $deepGrey;
    }
  }
}
