.load {
  position: relative;
  margin: 50px auto;
  width: 100px;
  height: 80px;
}

.gear {
  position: absolute;
  z-index: -10;
  width: 40px;
  height: 40px;
  animation: spin 5s infinite;
}

.two {
  left: 40px;
  width: 80px;
  height: 80px;
  animation: spin-reverse 5s infinite;
}

.three {
  top: 45px;
  left: -10px;
  width: 60px;
  height: 60px;
}

@keyframes spin {
  50% {
    transform: rotate(360deg);
  }
}

@keyframes spin-reverse {
  50% {
    transform: rotate(-360deg);
  }
}

.lilCircle {
  position: absolute;
  border-radius: 50%;
  box-shadow: inset 0 0 22px 1px grey, 0 0 41px white;
  width: 100px;
  height: 100px;
  opacity: 0.25;
}

.blurCircle {
  position: absolute;
  top: -19px;
  left: -19px;
}

.text {
  color: lightgray;
  font: {
    size: 18px;
    family: "Open Sans", sans-serif;
  }
  text-align: center;
}
