@import "../../styles/variables";
@import "../../styles/mixins";

.container {
  margin-top: 5px;
  margin-left: 5px;
}
.switch {
  position: relative;
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;

  &_checkbox {
    display: none;
  }
  &_label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
    margin: 0;
  }
  &_inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 34px;
      padding: 0;
      line-height: 34px;
      font-size: 14px;
      color: white;
      font-weight: bold;
      box-sizing: border-box;
    }
    &:before {
      content: "Yes";
      text-transform: uppercase;
      padding-left: 10px;
      background-color: $green;
      color: #fff;
    }
  }
  &_disabled {
    background-color: #ddd;
    cursor: not-allowed;
    &:before {
      background-color: #ddd;
      cursor: not-allowed;
    }
  }
  &_inner:after {
    content: "No";
    text-transform: uppercase;
    padding-right: 10px;
    background-color: #bbb;
    color: #fff;
    text-align: right;
  }
  &_switch {
    display: block;
    width: 24px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }
  &_checkbox:checked + &_label {
    .switch_inner {
      margin-left: 0;
    }
    .switch_switch {
      right: 0px;
    }
  }
}

.labelContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  width: fit-content;
  margin-bottom: 16px;

  input {
    opacity: 0;
    position: absolute;

    &:checked + div {
      background: $green;

      &:before {
        transform: translate(18px, -50%);
      }
    }
  }

  span {
    font-size: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
  }

  &__wrapper {
    position: relative;
    width: 44px;
    height: 24px;
    background: $text-muted;
    border-radius: 20px;
    padding: 2px;
    transition: 300ms all;
    cursor: pointer;

    &:before {
      transition: 300ms all;
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 35px;
      top: 50%;
      left: 4px;
      background: white;
      transform: translate(-2px, -50%);
    }
  }
}
