@import "../../styles/variables";

.datePickerCustom {
  width: 100%;
  height: 100%;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  border-radius: 8px;
  padding: 0 16px !important;
  // background: $deepGrey;
  // padding-right: 16.5px !important;

  p {
    display: none;
  }
  div {
    height: 100%;
  }

  ::before {
    border-bottom: none !important;
  }

  ::after {
    border-bottom: none !important;
  }
  button {
    padding: 0 !important;
  }

  input {
    border: none;
    width: 100%;
    letter-spacing: 0.01em;
    color: $black;
    padding: 0;
  }
}
