@import "../../styles/variables";

.sidebarContainer {
  background-color: $skyBlue;
  height: 100%;
  overflow-y: scroll;
  width: 280px;
  // position: fixed;

  @media (max-width: 1024px) {
    display: none;
    margin-top: 0px !important;
  }
}

.sidebarOpen {
  @media (max-width: 1024px) {
    transform: translateX(0%);
    transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    position: absolute;
    z-index: 100;
    display: flex !important;
  }
}

.sidebarClose {
  @media (max-width: 1024px) {
    transform: translateX(-100%);
    transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    position: absolute;
    z-index: 100;
    display: flex !important;
  }
}

.innerContainer {
  width: 280px;
  min-height: 100vh;
  margin-top: 10px;
  background-color: $skyBlue;
  top: 0;
  left: 0;
  padding: 50px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    margin-top: 0px !important;
    padding-top: 0px !important;
    overflow-y: scroll;
  }
}

.contentContainer {
  padding: 0px 0px;
  list-style-type: none;
  padding-top: 44px;
  margin-bottom: 50px;
  // max-height: 58vh;
  // overflow-y: scroll;

  li {
    margin-bottom: 3px;
    cursor: pointer;
    position: relative;

    &:hover {
      span {
        color: $primary !important;
      }
      svg path {
        fill: $primary;
      }
    }
  }
}

.listContentContainer {
  margin-bottom: 21px;
}

.listContentWrapper {
  display: flex;
  align-items: center;
}
.listLabel {
  margin-left: 12.5px;
  color: $secondary;
}

.mainName {
  font-weight: 500;
  line-height: 19px;
  color: #57575a;
  margin-top: 18.5px;
}

.subName {
  font-weight: 500;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #adb0b5;
  margin-top: 13px;
}

.recentsList {
  margin-top: 16px;
}
.recentsImg {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: 50%;
}

.recentsItem {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
}
