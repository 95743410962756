.layoutContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.pageContainer {
  flex: 1;
  overflow-y: scroll;
  padding-bottom: 51px;
  // padding-top: 28px;
  // padding-left: 46px;
  // padding-right: 46px;

  @media (max-width: 480px) {
    padding-bottom: 300px;
  }
}

.pageWrapper {
  padding: 45px;
}
