@import "../../styles/variables";

.headersTr {
  position: relative;
  height: 40px;
}
.table {
  width: 100%;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  overflow-x: auto;
  white-space: nowrap;
}
.tableWrapper {
  width: 100%;
  @media (max-width: 600px) {
    height: 100%;
  }
}
.tableContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-x: scroll;
  margin-bottom: 100px;
  // min-height: 78vh;
}
.th {
  height: 40px;
  top: 0px;
  position: sticky;
  &:first-child {
    padding: 0px 45px;
  }
}
.headerText {
  font-weight: normal;
}

.tableMeta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 45px;
  position: relative;
  span {
    color: $secondary;
  }
}
.pageNumWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pageNumContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8px;
  border: 1px solid #dedede;
  border-radius: 8px;
  margin-left: 8px;
  min-width: 41px;
}

.loading {
  opacity: 0.6;
}

.noBorderTr {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-radius: 8px;
}
.tableTop {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  &__item {
    display: flex;
    align-items: center;
    margin-right: 30px;
  }

  &__stat {
    padding: 8px 11.5px;
    background: #e7eaea;
    border: 1px solid #dedede;
    border-radius: 8px 8px 0px 0px;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #778080;
    margin-left: 18px;
  }
}
