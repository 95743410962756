.mainContainer {
  position: absolute;
  left: 0;
  right: 0;
  top: 15px;
  z-index: 1051;
}
.toastsContainer {
  position: absolute;
  right: 15px;
  z-index: 1051;
}
.toastTitle {
  margin-bottom: 5px;
  font-weight: 500;
  color: #fff;
}
.pointerClass {
  cursor: pointer;
}
.toastClose {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 12px;
}
