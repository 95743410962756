.headerContainer {
  padding: 28px 45px;
  background-color: #f8f8f9;
}
.pageTitle {
  font-size: 32px;
  line-height: 38px;
  letter-spacing: 0.01em;
}
.searchContainer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e7eaea;
  border-bottom: 1px solid #e7eaea;
  padding: 25px 0;
  margin-top: 30px;

  @media (max-width: 480px) {
    flex-direction: column;
  }
}
.searchInputWrapper {
  min-width: 291px;
}
.searchInput {
  border: 1px solid #e9e7e7;
}

.filterDropdown {
  min-width: 165px;
}
