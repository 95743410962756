.daysLeft {
  height: 60px;
  width: 47px;
  position: relative;
  margin-right: 5px;
  &:last-child {
    margin-right: none;
  }

  &__top {
    background-image: url("../../assets/img/Rectangle\ 65.png");
    height: 30px;
    width: 100%;
  }
  &__bottom {
    background-image: url("../../assets/img/Rectangle\ 66.png");
    height: 30px;
    width: 100%;
  }
  &__num {
    position: absolute;
    font-size: 40px;
    letter-spacing: -0.01em;
    line-height: 47px;
    left: 12px;
    top: 3px;
    font-weight: 600;
  }
}
