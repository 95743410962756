@import "../../styles/variables";

.controlLabel {
  color: $secondary;
  margin-bottom: 12px;
  display: block;
}

.controlInputContainer {
  border: 1px solid #dedede;
  border-radius: 8px;
  width: 100%;
  height: 40px;
  position: relative;
  > *:first-child {
    height: 100%;
  }
  input,
  textarea {
    padding: 0 16px;
    border-radius: 8px;
    &::placeholder {
      color: #b7b7b7;
    }
  }
}
.borderedControl {
  border: 1px solid #9da0a5;
}
.Active {
  input,
  textarea {
    background-color: $deepGrey;
  }
}

.iconContainer {
  position: absolute;
  right: 17px;
  top: 8px;
  cursor: pointer;
  &.left {
    left: 17px;
  }
}
.controlErrorContainer {
  border-color: $red;
}

.controlError {
  color: $red;
  margin-top: 6px;
  font-size: 14px;
  display: block;
}

.bottomText{
  cursor: pointer;
}