@import "./variables";
@import "./fonts";
@import "./mixins";
@import "./bootstrap";

.textInput {
  height: 100%;
  // border-radius: 8px;
  border: none;
  // padding: 0 16.5px;
  width: 100%;
  letter-spacing: 0.01em;
  color: $black;
  // background: $deepGrey;
}
.textInput::-webkit-input-placeholder {
  color: $secondary;
}
.textInput::-moz-placeholder {
  color: $secondary;
}
.textInput:-ms-input-placeholder {
  color: $secondary;
}
.textInput::-ms-input-placeholder {
  color: $secondary;
}
.textInput:focus {
  outline: none;
}

.formTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.01em;
  margin-bottom: 29px;
}

.successCard {
  background-color: #e0f5e6 !important;
  border: 1px solid #ededed !important;
  color: $green !important;
}
.dangerCard {
  background-color: rgba(247, 85, 157, 0.1) !important;
  border: 1px solid #ededed !important;
  color: #f7559d !important;
}

.paddingLeft {
  padding-left: 28px;
}

.paddingRight {
  padding-right: 28px;
}

.padding_x {
  padding-right: 28px;
  padding-left: 28px;
}

.bg_deepGrey {
  background-color: $deepGrey;
}

.bg_lightGrey {
  background-color: $lightGrey;
}

.w_fitContent {
  width: fit-content !important;
}

.noStateCard {
  background-color: #eee !important;
  color: $secondary !important;
}

.bgHoverClass_grey {
  cursor: pointer;
  transition: all 0.5s ease;
  &:hover {
    background-color: $lightGrey;
  }
}

.opacity5 {
  opacity: 0.5;
}
.pointer {
  cursor: pointer;
}
