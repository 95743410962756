.mapPlaceholder {
  width: 100%;
  height: 245px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.label {
  margin-bottom: 12px;
  font-weight: 600;
}
.pinIcon {
  height: 40px;
  width: 48px;
  background: #ffffff;
  border: 1px solid #9da0a5;
  border-radius: 8px;
  //   flex-direction: ;
}
