@import "./fonts";
@import "./mixins";
@import "./variables";
@import "./bootstrap";
@import "~react-image-gallery/styles/css/image-gallery.css";

* {
  font-family: CharlieDisplay;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  color: $black;
}
span,
div {
  letter-spacing: 0.02em;
}
thead,
th {
  background-color: #f8f8f9;
}

tr {
  border-bottom: solid 1px #e7eaea;
  height: 80px;
  &:hover {
    cursor: pointer;
    background-color: #f8f8f9;
  }
}

td,
th {
  // padding: 21px 0;
  &:first-child {
    padding-left: 45px;
  }
}
