@import "../../styles/variables";

.textArea {
  border-radius: 8px;
  border: none;
  padding-top: 11px !important;
  padding-bottom: 16px !important;
  width: 100%;
  letter-spacing: 0.01em;
  color: $black;
  display: block;
  resize: none;
}
.textArea:focus {
  outline: none;
}
.textAreaInputContainer {
  height: unset;
}
