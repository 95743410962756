@import "../../styles/variables";

.containerClass {
  display: flex;
  height: 200px;
  justify-content: space-between;
}

.valueClass{
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.barClass {
  background-color: red;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.labelClass {
  margin-top: 20%;
  font-size: 16px;
}