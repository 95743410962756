@import "../../styles/variables";
.dropdownComponent {
  width: 100%;
  height: 100%;
  padding: 0 16px;
  border: none;
  background-color: $white;
  cursor: pointer;
  border-bottom: solid 1px #dfdfdf;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  @media (max-width: 480px) {
    overflow-y: scroll;
  }
}

.selectorContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  line-height: 19px;
  letter-spacing: 0.01em;
}
.dropdownitems {
  text-decoration: none;
  height: 44px;
  padding: 0 23px;
  cursor: pointer;
  padding-right: 17px;
  &:hover {
    background-color: $deepGrey;
  }
}

.oval {
  width: 17px;
  height: 17px;
  margin: 0 17px 0 0;
  border: solid 1px #8f92a1;
  border-radius: 50%;
}

.selectedItem {
  background-color: $deepGrey;
}
.dropdownList {
  width: 100%;
  max-height: 320px;
  overflow-y: scroll;
}

.dropdownListCon {
  width: 100%;
  max-height: 320px;
}

.label {
  text-align: center;
}

.items {
  font-size: 16px;
  line-height: 2.25;
  color: #45414d;
  text-transform: capitalize;
  text-decoration: none;
  white-space: pre;
}

.searchContainer {
  width: 100%;
  padding: 18px 23px;
}

.search {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  background-color: #f9f9f9;
  padding: 0 23px;
  border: none;
}

.search:focus {
  outline: none;
}

.search::placeholder {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: #8f92a1;
}

.dropdownOverlayCon {
  position: absolute;
  border-radius: 8px;
  z-index: 1;
  background: white;
  left: 0;
  right: 0;
  margin-top: 10px;
  box-shadow: -1px 8px 10px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #eee;
}
.placeholder {
}
